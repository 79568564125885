import { css } from 'lit';

export const skillswaveBodySmallStyles = css`
  .skillswave-body-small {
    color: var(--d2l-color-ferrite);
    font-family: 'Poppins', sans-serif;
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 0.8rem;
  }
`;

export const skillswaveHeading1Styles = css`
  .skillswave-heading-1 {
    color: var(--d2l-color-ferrite);
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 36px;
  }
`;

export const skillswaveHeading2Styles = css`
  .skillswave-heading-2 {
    color: var(--d2l-color-ferrite);
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 32px;
  }
`;

export const skillswaveHeading3Styles = css`
  .skillswave-heading-3 {
    color: var(--d2l-color-ferrite);
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 30px;
  }
`;

export const skillswaveParagraphSubtleStyles = css`
  .skillswave-paragraph-subtle {
    color: var(--d2l-color-tungsten);
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const skillswaveButtonStyles = css`
  .skillswave-button-text {
    color: var(--d2l-color-regolith);
    font-family: 'Poppins', sans-serif;
    font-size: 0.7rem;
    font-weight: 700;
    line-height: 18px;
  }
`;

export const skillswaveLabelStyles = css`
  .skillswave-label {
    color: var(--d2l-color-tungsten);
    font-family: 'Poppins', sans-serif;
    font-size: 0.7rem;
    line-height: 21px;
  }
`;
