import { css, html, LitElement, nothing } from 'lit';

import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/button/button-subtle.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import '@/app/shared/components/general/nova-selection-card/nova-selection-card.js';
import '@/app/shared/components/general/nova-selection-group/nova-selection-group.js';
import '@/app/shared/components/general/nova-data-alert/nova-data-alert.js';
import { skillswaveHeading2Styles, skillswaveParagraphSubtleStyles } from '@/app/shared/styles/skillswave-text-styles.js';
import { LocalizeNova } from '../../../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaFlowStepMixin } from '../../../../../../shared/mixins/nova-flow-step-mixin/nova-flow-step-mixin.js';

export const OBJECTIVES = {
  MY_COMPANY: 'my-company',
  MY_TEAM: 'my-team',
  TESTING: 'testing',
};

class ClientOnboardingObjective extends NovaFlowStepMixin(RequesterMixin(LocalizeNova(LitElement))) {

  static get properties() {
    return {
      value: { type: String },
    };
  }

  static get styles() {
    return [
      skillswaveHeading2Styles,
      skillswaveParagraphSubtleStyles,
      css`
        :host {
          display: block;
        }

        .skillswave-heading-2 {
          background: linear-gradient(to bottom right, #000000, #006fbf);
          background-clip: text;
          margin-bottom: 16px;
          -webkit-text-fill-color: transparent;
        }

        .skillswave-paragraph-subtle {
          margin-bottom: 16px;
        }

        nova-selection-card {
          max-width: 224px;
        }

        nova-selection-group {
          margin-bottom: 48px;
        }

        @keyframes slideInFadeIn {
          from {
            opacity: 0;
            transform: translateY(-40px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .slide-in-fade-in {
          animation: slideInFadeIn 0.5s ease-in-out;
        }

        @media (prefers-reduced-motion: reduce) {
          .slide-in-fade-in {
            animation: none;
          }
        }
      `,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');

    this.addEventListener('nova-selection-group-selection-changed', this._onSelectionChanged);
  }

  firstUpdated() {
    if (this._existingData?.objective) {
      this._updateData(this._existingData.objective);
      const selectionGroup = this.shadowRoot.querySelector('nova-selection-group');
      if (selectionGroup) {
        selectionGroup.selectedValue = this._existingData.objective;
      }
    }
  }

  disconnectedCallback() {
    this.removeEventListener('nova-selection-group-selection-changed', this._onSelectionChanged);
    super.disconnectedCallback();
  }

  _onSelectionChanged(e) {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    this._updateData(e.detail.selectedValue);
  }

  _updateData(value) {
    this.value = value;
    this.nextEnabled = true;
    this.data = { objective: this.value };

    const newTitle = this.value === OBJECTIVES.MY_COMPANY
      ? 'My Company'
      : 'My Team';
    this.updateStep({ name: 'industry', displayName: newTitle });
  }

  get _alertContent() {
    if (this.value === OBJECTIVES.MY_COMPANY) {
      return this.localize('view-client-onboarding.client-onboarding-objective.alert.my-company', { count: 1452, strong: this.l10nTags.strong() });
    } else {
      return this.localize('view-client-onboarding.client-onboarding-objective.alert.my-team', { count: 1452, strong: this.l10nTags.strong() });
    }
  }

  render() {
    return html`
      <div>
        <h2 class="skillswave-heading-2">
          ${this.localize('view-client-onboarding.client-onboarding-objective.title')}
        </h2>
        <p class="skillswave-paragraph-subtle">
          ${this.localize('view-client-onboarding.client-onboarding-objective.description')}
        </p>
        <nova-selection-group for="objective" layout="row" gap="36px">
          <nova-selection-card
            value=${OBJECTIVES.MY_COMPANY}
            icon="tier1:eportfolio"
            title=${this.localize('view-client-onboarding.client-onboarding-objective.my-company.title')}
            description=${this.localize('view-client-onboarding.client-onboarding-objective.my-company.description', { tenantName: this.session.tenant.name })}
          ></nova-selection-card>
          <nova-selection-card
            value=${OBJECTIVES.MY_TEAM}
            icon="tier1:group"
            title=${this.localize('view-client-onboarding.client-onboarding-objective.my-team.title')}
            description=${this.localize('view-client-onboarding.client-onboarding-objective.my-team.description')}
          ></nova-selection-card>
        </nova-selection-group>
        ${this.nextEnabled ? html`
          <nova-data-alert class="slide-in-fade-in">
            ${this._alertContent}
          </nova-data-alert>
        ` : nothing}
        <div class="buttons" style="margin-top: 20px;">
          <d2l-button
            primary
            @click=${this.goNext}
            ?disabled=${!this.nextEnabled}
          >
            ${this.localize('components.buttons.continue')}
          </d2l-button>
          <d2l-button-subtle
            @click=${this.goBack}
            text=${this.localize('components.buttons.previous')}
          ></d2l-button-subtle>
      </div>
    `;
  }

}

window.customElements.define('client-onboarding-objective', ClientOnboardingObjective);
