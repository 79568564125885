import '@brightspace-ui/core/components/inputs/input-textarea.js';
import '@brightspace-ui/core/components/button/button.js';

import { css, html, LitElement, nothing } from 'lit';

import { bodySmallStyles, heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../shared/mixins/nova-nav/nova-nav.js';
import { PLG_USER_LIMIT } from '../../../../shared/constants.js';

import '../../../main/components/general/suggest-activity/email-list-input/email-list-input.js';

class SettingsInviteUser extends LocalizeNova(RequesterMixin(SkeletonMixin(NovaNavMixin(LitElement)))) {
  static get properties() {
    return {
      emailList: { type: Array },
      results: { type: Object },
      _seatsLeft: { type: Number },
    };
  }

  static get styles() {
    return [
      heading2Styles,
      inputLabelStyles,
      bodySmallStyles,
      css`
        :host {
          display: flex;
          flex: 1;
        }
        .d2l-heading-2 {
          margin-top: 0;
        }

        .container {
          display: flex;
          flex-direction: column;
          min-height: 100%;
          padding: 20px;
        }
        .content {
          flex: 1;
        }
        .button-container {
          display: flex;
          gap: 10px;
          margin-top: auto;
          padding-bottom: 40px;
        }
        .email-list {
          display: block;
          width: 100%;
        }
        .email-description {
          font-weight: bold;
        }
        .full-width-alert {
          margin-top: 16px;
          width: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.emailList = [];
    this.results = {
      successful: [],
      user_already_exists: [],
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');

    const countResponse = await this.client.countUsers({
      tenantId: this.session.tenant.id,
      active: true,
    });
    this._seatsLeft = PLG_USER_LIMIT - countResponse.total;
  }

  get buttonText() {
    return this.localize('settings-invite-user.add_num_users', { num_users: this.results.successful.length });
  }

  async _emailListChanged(e) {
    this.emailList = e.detail.emails;
    this.results = await this.client.inviteUsers(this.session.tenant.id, this.emailList, false);
  }

  async submitUsers() {
    try {
      await this.client.inviteUsers(this.session.tenant.id, this.emailList, true);
      this.navigate('/settings/user-management');
      this.session.toast({ type: 'success', message: this.localize('invite-user.success', { numUsers: this.results.length }) });
    } catch (err) {
      this.session.toast({ type: 'error', message: this.localize('invite-user.error') });
    }
  }

  cancel() {
    this.navigate('/settings/user-management');
  }

  get invalidEmails() {
    return this.results?.user_already_exists ? this.results.user_already_exists : [];
  }

  _renderUserAlreadyExistsAlert() {
    if (!this.results?.user_already_exists?.length) return nothing;

    const key = this.results.user_already_exists.length > 1
      ? 'settings-invite-user.user_already_exists_plural'
      : 'settings-invite-user.user_already_exists_singular';

    return html`
      <d2l-alert type="warning" class="full-width-alert">
        ${this.localize(key, { num_warnings: this.results.user_already_exists.length })}
      </d2l-alert>
    `;
  }

  _renderUserLimitAlert() {
    if (this._seatsLeft === undefined) return nothing;
    if (this._seatsLeft > 0 && this.emailList.length < this._seatsLeft) return nothing;

    return html`
      <d2l-alert type="warning" class="full-width-alert">
        ${this.localize('settings-invite-user.user_limit_warning', { max_users: PLG_USER_LIMIT })}
      </d2l-alert>
    `;
  }

  render() {
    return html`
      <div class="container">
        <div class="content">
          <h2 class="d2l-heading-2">Add users</h2>
          <p class="desc-2">${this.localize('settings-invite-user.all_users')}</p>

          <label class="d2l-input-label-required" for="email-list">
            ${this.localize('settings-invite-user.email_addresses')}
          </label>
          <email-list-input
            rows="6"
            limit="${this._seatsLeft ?? 0}"
            hide-label
            label="Email addresses"
            class="email-list"
            .invalidEmails="${this.invalidEmails}"
            .emails=${this.emailList}
            @emails-changed="${this._emailListChanged}">
          </email-list-input>

          ${this._renderUserAlreadyExistsAlert()}
          ${this._renderUserLimitAlert()}
        </div>
        <div class="button-container">
          <d2l-button primary ?disabled="${this.results.successful.length === 0 || this.results.successful.length > this._seatsLeft}" @click="${this.submitUsers}">
            ${this.buttonText}
          </d2l-button>
          <d2l-button @click="${this.cancel}">
            ${this.localize('general.button-text.cancel')}
          </d2l-button>
        </div>
      </div>
    `;
  }
}

window.customElements.define('settings-invite-user', SettingsInviteUser);
