import { html, LitElement } from 'lit';

import { LocalizeNova } from '@/app/shared/mixins/localize-nova/localize-nova.js';
import { NovaFlowStepMixin } from '@/app/shared/mixins/nova-flow-step-mixin/nova-flow-step-mixin.js';

class NovaFlowStep extends NovaFlowStepMixin(LocalizeNova(LitElement)) {

  firstUpdated() {
    super.firstUpdated();
    const slot = this.shadowRoot.querySelector('slot');
    if (slot) {
      slot.addEventListener('slotchange', this._handleSlotChange.bind(this));
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    const slot = this.shadowRoot.querySelector('slot');
    if (slot) {
      slot.removeEventListener('slotchange', this._handleSlotChange.bind(this));
    }
  }

  _handleSlotChange() {
    const finishButton = this.querySelector('#nova-flow-finish');
    const nextButton = this.querySelector('#nova-flow-next');
    const backButton = this.querySelector('#nova-flow-back');
    if (finishButton) {
      finishButton.addEventListener('click', this.finish);
    }
    if (nextButton) {
      nextButton.addEventListener('click', this.goNext);
    }
    if (backButton) {
      backButton.addEventListener('click', this.goBack);
    }
  }

  render() {
    return html`
        <slot></slot>
    `;
  }

}

window.customElements.define('nova-flow-step', NovaFlowStep);
